<template>
  <template-block
    mod-class
    content-class="create-order_unregistered"
    title-page="Новая задача"
  >
    <!-- <div v-if="device.id" class="device-info">
      <img
        :src="
          device.pictureUrl.length
            ? device.pictureUrl
            : 'https://placehold.it/90x80'
        "
        width="90"
        height="80"
        class="mr-2"
      />
      <div class="ml-2">
        <div class="mb-2 font-semibold text-center">{{ device.title }}</div>
        <div class="mb-2 font-semibold text-center">{{ device.serialNum }}</div>
        <div class="mb-2 font-semibold text-center">
          {{ device.stickerNum }}
        </div>
      </div>
    </div> -->
    <form
      class="small-create-task main-container"
      @submit.prevent="createRequest"
    >
      <label>
        Название задачи
        <input
          type="text"
          v-model="title"
          placeholder="Название задачи вводится пользователем"
          @focus="doFocusTitle"
          @blur="doBlurTitle"
        />
      </label>
      <label v-if="device && device.companyId && device.companyId !== '425'">
        Тип задачи
        <!-- <input v-model="title" type="text" /> -->
        <b-select v-model="selectedTypeId" class="choose w-100">
          <option v-for="type in types" :key="type.id" :value="type.id">{{
            type.title
          }}</option>
        </b-select>
      </label>
      <label>
        Описание задачи
        <textarea
          v-model="texts.desc"
          placeholder="Опишите задачу"
          @focus="doFocusDescription"
          @blur="doBlurDescription"
        />
      </label>

      <button
        :disabled="isDataAlreadySended || !title.length"
        class="create-task"
      >
        <span v-if="!isOrderSubmitInProgress">Создать задачу</span>
        <b-spinner v-else small></b-spinner>
      </button>
    </form>
  </template-block>
</template>

<script>
import TemplateBlock from "@/components/TepmplateBlock";
import RequestManager from "../../function/request/RequestManager";

const DEFAULT_NEOPHARM_ORDER = {
  deviceId: "",
  name: "Название заявки, вводится пользователем",
  description: "Описание заявки, вводится пользователем",
  priorityId: "9",
  serviceId: "1713",
  statusId: "31",
  typeId: "1175",
  categoryId: "855",
  impactedUserId: "1347"
};

export default {
  name: "OrdersCreateNeopharm",
  components: { TemplateBlock },
  data() {
    return {
      office: {},
      deviceId: "",
      device: {},
      defaultTitle: "Прочее",
      defaultDescription: "Описание задачи",
      title: "Прочее",
      isDataAlreadySended: false,
      texts: {
        desc: "",
        shortDesc: "",
        condition: ""
      },
      isOrderSubmitInProgress: false,
      types: [
        { id: "2", title: "Вызов мастера" },
        { id: "3", title: "Замена расходных материалов" },
        { id: "5", title: "Прочее" }
      ],
      selectedTypeId: "5",
      debugFront: false,
      debugBack: false
    };
  },
  computed: {
    orderCreateForm() {
      return {
        title: this.title,
        texts: this.texts,
        typeId: this.selectedTypeId,
        statusId: "1",
        priority: 1
      };
    }
  },
  methods: {
    doFocusTitle() {
      if (this.title === this.defaultTitle) {
        this.title = "";
      }
    },
    doBlurTitle() {
      if (!this.title.length) {
        this.title = this.defaultTitle;
      }
    },
    doFocusDescription() {
      if (this.texts.desc === this.defaultDescription) {
        this.texts.desc = "";
      }
    },
    doBlurDescription() {
      /* if (!this.texts.desc.length) {
        this.texts.desc = this.defaultDescription;
      } */
    },
    async createRequest() {
      try {
        this.isDataAlreadySended = true;
        this.isOrderSubmitInProgress = true;
        const neoPharmOrder = Object.assign({}, DEFAULT_NEOPHARM_ORDER);
        neoPharmOrder.deviceId = this.deviceId;
        neoPharmOrder.name = this.title;
        neoPharmOrder.description = this.texts.desc;
        neoPharmOrder.impactedUserId = this.office.neoPharmOfficeId;
        if (!this.debugFront) {
          await RequestManager().neoPharmOrderApi.createNeoPharmOrder(
            this.debugBack,
            neoPharmOrder
          );
          this.isDataAlreadySended = true;
        } else {
          console.log(neoPharmOrder);
        }
        this.$bvToast.toast("Задача успешно создана", {
          variant: "success",
          title: "Успех"
        });
        this.$router.replace({ name: "OrderWasCreateMessage" });
      } finally {
        this.isOrderSubmitInProgress = false;
      }
    }
  },
  async mounted() {
    this.deviceId = this.$route.query.device;
    /* const deviceResponse = await RequestManager().deviceApi.getDevice(
      this.deviceId
    );
    this.device = deviceResponse; */
    const debugFrontSrc = this.$route.query.debugFront;
    const debugBackSrc = this.$route.query.debugBack;
    if (debugFrontSrc) {
      this.debugFront = JSON.parse(debugFrontSrc);
    }
    if (debugBackSrc) {
      this.debugBack = JSON.parse(debugBackSrc);
    }
    this.office = await RequestManager().neoPharmOfficeApi.getOfficeByDevice(
      this.deviceId
    );
    // this.title = this.defaultTitle;
    // this.texts.desc = this.defaultDescription;
  }
};
</script>

<style lang="scss" scoped>
.device-info {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.mr-2 {
  margin-right: 10px;
}
.ml-2 {
  margin-left: 10px;
}
.mb-2 {
  margin-bottom: 10px;
}
.font-semibold {
  font-weight: 600;
}
.text-center {
  text-align: center;
}

.small-create-task {
  padding: 5px 10px;
  width: 100%;
  max-width: 500px;
  //height: 645px;
  background: var(--main-card-color);
  border-radius: 10px;
  position: relative;
  z-index: 1001;
  border: 3px solid #cad2ef;
}

label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.3px;
  margin: 20px auto 0 auto;
  .required {
    vertical-align: top;
    margin-right: 4px;
  }
  textarea {
    padding: 8px 14px;
    display: block;
    width: 100%;
    height: 154px;
    border: 1px solid var(--auth-input-bg);
    color: var(--main-text-color);
    background: var(--auth-input-bg);
    border-radius: 4px;
    resize: none;
    outline: none;
    margin-top: 3px;
  }
  input {
    padding: 0 8px;
    display: block;
    width: 100%;
    height: 46px;
    border: 1px solid var(--auth-input-bg);
    color: var(--main-text-color);
    background: var(--auth-input-bg);
    border-radius: 4px;
    outline: none;
    margin-top: 3px;
  }
}

input::placeholder,
textarea::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.3px;
  color: #9a9a9a;
}

.choose {
  width: 350px;
  height: 46px;
  background-color: var(--auth-input-bg);
  border-radius: 4px;
  margin-top: 3px;
}

.investment {
  width: 96px;
  height: 19px;
  background: #f1f1f3;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.333333px;
  color: #9a9a9a;
  margin-top: 10px;
}

.create-task {
  width: 235px;
  height: 46px;
  display: block;
  margin: 16px auto 0 auto;
  background: #2c698c;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.3px;
  color: #ffffff;
  border: 3px solid #cad2ef;
}
.create-task:disabled {
  background: #cad2ef;
  cursor: not-allowed;
  border: none;
}

.spinner {
  width: 235px;
  height: 46px;
  display: block;
  margin: 16px auto 0 auto;
  border-radius: 4px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.3px;
}
</style>
